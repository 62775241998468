<template>
  <section class="jci_course container970">
    <div class="top-banner">
      <!-- <img src="../../assets/images/bg-03.jpg" style="width: 100%; height: 100%" class="rounded"></img> -->
      <img src="../../assets/images/public/small_banner.png" alt="" class="small_banner">
      <div>
        <h3>My Training</h3>
      </div>
    </div>

    <el-card class="table-card mt-4 training_card">
      <section class="top_container">
        <el-row class="p-0" :gutter="10">
          <el-col :xs="24" :sm="24" :md="12">
            <div class="search">
              <el-input placeholder="Training Name" v-model="keyword" @keyup.enter.native="getMyTrainingSearch"
                @clear="getMyTrainingSearch" clearable style="width: 173px;" class="mr-2">
                <i slot="suffix" class="el-input__icon el-icon-search cursor-pointer" @click="getMyTrainingSearch"></i>
              </el-input>
              <el-select placeholder="Status" v-model="status" clearable @change="getMyTrainingSearch"
                style="width: 173px;">
                <el-option label="Registration Open" :value="2"></el-option>
                <el-option label="Training Started" :value="3"></el-option>
                <el-option label="Training Closed" :value="4"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12">
            <div class="circle_content d-flex align-items-center justify-content-around">
              <div class="circle_item">
                <i class="circle" style="background: #53c5b8;"></i>
                <span>Registration Open</span>
              </div>
              <div class="circle_item">
                <i class="circle" style="background: #FFBA68;"></i>
                <span>Training Started</span>
              </div>
              <div class="circle_item">
                <i class="circle" style="background: #999999;"></i>
                <span>Training Closed</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </section>

      <div class="training_container" v-if="courseTrainingList.length">
        <div class="d-flex flex-wrap height_scroll">
          <div class="small_card" v-for="v of courseTrainingList" :key="v.id"
            @click="$router.push(`/training_detail?id=${v.training_id}`)">
            <section class="training_content d-flex align-items-center justify-content-between">
              <h5 class="training_title">{{v.training}}</h5>
              <i class="circle" :style="{background:v.status==2?'#53c5b8':v.status==3?'#FFBA68':'#999999'}"></i>
            </section>
            <section class="bottom mt-2">
              <span class="left">Trainer: </span>
              <span class="one_line" :title="v.trainer">{{ v.trainer }}</span>
            </section>
            <section class="bottom">
              <span class="left">Language: </span>
              <span>{{ v.language }}</span>
            </section>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center" style="background:#fff;margin: 60px 0" v-else>
        <img style="width:250px;" src="../../assets/images/group/empty1.png" alt="" />
      </div>

      <div class="d-flex justify-content-end container mb-3">
        <el-pagination background layout="prev, pager, next" :page-size="12" @current-change="handleCurrentChange"
          :current-page.sync="page" :total="total" hide-on-single-page>
        </el-pagination>
      </div>
    </el-card>

  </section>
</template>

<script>
  export default {
    data() {
      return {
        courseTrainingList: [],
        keyword: '',
        status: '',
        page: 1,
        total: 0,
      };
    },

    components: {},

    mounted() {
      this.getMyTraining()
    },

    methods: {
      handleCurrentChange(page) {
        this.page = page;
        this.getMyTraining();
      },

      getMyTrainingSearch() {
        this.page = 1
        this.getMyTraining()
      },

      // 我的培训
      getMyTraining() {
        let data = { keyword: this.keyword, status: this.status, page: this.page, per_page: 12 }
        this.$http.getMyTraining(data).then((res) => {
          if (res.status == 200) {
            this.courseTrainingList = res.data.data;
            this.total = res.data.total;
          }
        });
      },

    },
  };
</script>
<style lang="scss" scoped>
  .jci_course {
    .height_scroll {
      max-height: 700px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .top-banner {
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      position: relative;
      background-color: rgb(66, 102, 176);

      .small_banner {
        height: 100%;
        position: absolute;
      }

      div {
        height: 230px;
        width: 100%;
        /* background: rgba($color: #999, $alpha: 0.3); */
        display: flex;
        justify-content: center;
        border-radius: 5px;
        align-items: center;
        position: absolute;

        >h3 {
          color: #fff;
          font-size: 30px;
        }
      }
    }

    .basic_info {
      .left {
        width: 200px;
      }

      .right {
        color: #999;
        flex: 1;

        ::v-deep p {
          margin: 0 !important;
          padding: 0 !important;
        }
      }
    }

    ::v-deep .table-card .el-card__body {
      padding-top: 0px;
    }

    .top_container {
      padding: 10px 20px;
      border-bottom: 1px solid #eee;
    }

    .training_card {
      ::v-deep .el-card__body {
        padding: 0 !important;
      }
    }

    .training_container {
      padding: 20px;

      .training_content {
        height: 50px;
        border-bottom: 1px solid #eee;
        padding: 0 10px;
      }

      .training_title {
        line-height: 50px;
        border-bottom: 1px solid #eee;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex: 1;
      }

      .left {
        color: #999;
      }

      .bottom {
        padding-left: 10px;
        display: flex;
      }

      .one_line {
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .circle_content {
      margin-top: 12px;

      .circle_item {
        display: flex;
        align-items: center;
        margin-left: 10px;
      }
    }

    .small_card {
      width: 300px;
      height: 120px;
      margin-right: 8px;
      background: #f8f8f8;
      border: 1px solid #eeeeee;
      border-radius: 5px;
      margin-bottom: 10px;
      cursor: pointer;

      h5 {
        font-size: 18px;
        font-family: Montserrat, Montserrat-Regular;
        font-weight: 400;
        color: #333333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .to_my_training {
    cursor: pointer;
    border-bottom: 1px solid #EDBE38;
    color: #EDBE38;
    line-height: 18px;
  }
  @media (min-width: 600px) {
    .top-banner {
      height: 230px;
      .small_banner {
        left: 70px;
      }
    }
    .circle {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        margin-right: 5px;
      }
  }
  @media (max-width: 600px) {
    .top-banner{
      height: 180px;
      h3{
        font-size: 16px !important;
      }
      .small_banner {
        left: 25px;
        width: 70px;
      }
    }
    .search {
      ::v-deep .el-input, .el-select {
        width: 100% !important;
        margin: 5px 0;
      }
    }
    .circle_item{
      font-size: 11px;
      line-height: 10px;
      .circle{
        width: 10px;
        height: 10px ;
        border-radius: 50%;
        margin-right: 2px;
      }
    }
  }
</style>